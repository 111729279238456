import React from "react";
import { Col, message, Row, Spin } from 'antd';
import "./SalesCart.css";
import CartTable from "./CartTable/CartTable";
import { useState, useEffect } from "react";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { showPaymentDialog } from "./PaymentDialog/showPaymentDialog";
import { apiMakePayWithCash, apiMakePayWithPaypal } from "services/salesService";
import { socket } from "services/socketService"
import { useSelector } from "react-redux";
import { SOCKET_EVENT_S_NEW_ORDER } from "services/CONSTANTS";
import SaleItemWidget from "../SaleItemWidget/SaleItemWidget";
function SalesCart(props) {
    const { dataList, RemoveCartAction, table_name, cleanAllCartAction} = props;
    const [searchList, setSearchList] = useState([]);
    const [totalBudget, setTotalBudget] = useState(0);
    const [isBusy, setIsBusy] = useState(false);
    const socketDataStore = useSelector((x) => x.socketDataStore);
    const mSocket = socket
    useEffect(() => {
        setSearchList(dataList)
        getTotalBudget()
    }, [dataList]);

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const getTotalBudget = () => {
        let total_buget = 0
        for (let i = 0; i < dataList.length; i++) {
            let radioOption = dataList[i].radioOption
            let enter_qty = dataList[i].enter_qty
            let each_item_budget = parseFloat(dataList[i].recipeList[radioOption].total_price)
            let recipe_extra = dataList[i].recipe_extra
            for (let j = 0; j< recipe_extra.length; j++) {
                if (recipe_extra[j].value === true) {
                    each_item_budget += parseFloat(recipe_extra[j].total_price)
                }
            }
            total_buget = parseFloat(total_buget) + parseFloat(each_item_budget) * parseFloat(enter_qty)
        }
        setTotalBudget(Math.round(total_buget))
    }
    const payNow = async () => {
        if (dataList.length == 0) {
            message.error("Please Add Cart Item!")
            return
        }
        if (table_name === undefined || table_name === "" || table_name === null) {
            message.error("Table Info is not vaild!. Please scan QR code to reload site, again")
            return
        }

        var res = await showPaymentDialog({
            totalBudget: parseFloat(totalBudget).toFixed(2),
        });
        if (res == null) return;

        let cartList = []
        for(let i = 0; i< dataList.length ;i++) {
            let radioOption = dataList[i].radioOption
            let enter_qty = dataList[i].enter_qty
            let recipe_extra = dataList[i].recipe_extra
            let recipe_extra_list = []
            for (let j = 0; j< recipe_extra.length; j++) {
                if (recipe_extra[j].value === true) {
                    recipe_extra_list.push(recipe_extra[j])
                }
            }

            let tmp_extraOptionList = []
            let tmp_dataExtraList = dataList[i].extraOptionList
            for (let ii = 0;ii<tmp_dataExtraList.length;ii++){
              let tmp_optionList = tmp_dataExtraList[ii].optionList
              let checked_tmp_optionList = tmp_optionList.filter(x=>x.value === true)
              if (checked_tmp_optionList !== undefined && checked_tmp_optionList.length > 0) {
                tmp_extraOptionList.push({
                  optionList : checked_tmp_optionList,
                  name : tmp_dataExtraList[ii].name,
                  id : tmp_dataExtraList[ii].id,
                })
              }
              
            }

            let cart = {
                id : dataList[i].recipeList[radioOption].id,
                is_inventory : dataList[i].is_inventory,
                total_price : parseFloat(dataList[i].recipeList[radioOption].total_price) * parseFloat(enter_qty),
                average_price_per_unit : dataList[i].recipeList[radioOption].total_price,
                enter_qty : enter_qty,
                thumbnail_image : dataList[i].recipeList[radioOption].thumbnail_image,
                name : dataList[i].recipeList[radioOption].name,
                note : dataList[i].note,
                extraOptionList : tmp_extraOptionList,
                recipe_extra : recipe_extra_list,
            }
            cartList.push(cart)
        }
        //console.log("res.cartItemInfo",res.cartItemInfo)
        if (res.cartItemInfo['payment_method'] === 0) {
            let data = { customer_name: res.cartItemInfo['customer_name'], totalBudget: totalBudget, cartList: cartList, table_name: table_name }
            setIsBusy(true);
            apiMakePayWithPaypal(data)
                .then((data) => {
                    setIsBusy(false);
                    window.open(data['url'])
                    mSocket.emit(SOCKET_EVENT_S_NEW_ORDER, data['order'])
                    cleanAllCartAction()
                })
                .catch((err) => {
                    message.error("Something is wrong!")
                    setIsBusy(false);
                });
        }
        else {
            let data = { customer_name: res.cartItemInfo['customer_name'], totalBudget: totalBudget, cartList: cartList, table_name: table_name }
            setIsBusy(true);
            apiMakePayWithCash(data)
                .then((data) => {
                    setIsBusy(false);
                    message.success("Your order has been submitted succesfully!")
                    mSocket.emit(SOCKET_EVENT_S_NEW_ORDER, data['order'])
                    cleanAllCartAction()
                })
                .catch((err) => {
                    message.error("Something is wrong!")
                    setIsBusy(false);
                });
        }
    }
    const handleChangeInfoforEachItem = (record) => {
        let updateData = [...dataList];
        var storeIndex = updateData.findIndex(x => x.id == record.id);

        record["total_price"] = record["average_price_per_unit"] * record.enter_qty
        updateData.splice(storeIndex, 1, record);
        setSearchList(updateData)
        getTotalBudget()
    }

    const cartListUI = searchList == undefined || searchList.length == 0 ? <div>You don't have any Cart list</div> : searchList.map((info, index) => {
        return (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
                <SaleItemWidget
                    type="2" //cart list
                    info={info}
                    handleChangeInfoforEachItem={handleChangeInfoforEachItem}
                    RemoveCartAction={RemoveCartAction}
                    dataList={searchList}
                    setDataList={setSearchList}
                />
            </Col>
        );
    });

    return (
        <div>
            <Spin spinning={isBusy}>

                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <TitleSearch
                            onSearch={handleSearch}
                            onChange={handleChange}
                            onPressEnter={handleSearch}
                        />
                    </Col>
                    <Col xs={23} sm={23} md={23} lg={23} xl={11} >
                        <Row gutter={[16, 16]} justify="end">
                            <span style={{ margin: 10 }}>Total Price : ฿ {totalBudget}</span>
                            <CommonButton
                                customClass={"cart-pay-basic-unit-add-button"}
                                onClick={() => {
                                    payNow();
                                }}>
                                Confirm Order
                            </CommonButton>
                        </Row>
                    </Col>

                </Row>
                <Row align={"middle"} gutter={16} style={{ marginTop: "10px", textAlign: 'center' }} justify="center">
                    {cartListUI}
                </Row>
            </Spin>
        </div>
    );
}

export default SalesCart;
