// @flow strict

import * as React from "react";
import { Select } from "antd";
import "./ProductTypeSelector.css";
const { Option } = Select;

function ProductTypeSelector(props) {
  const { field, productInfo, setProductInfo, isCategory, onChangeHandle } = props;
  const { displayField, idField } = props;
  const { errorField, setErrorField } = props;
  const { placeholder, list, disabled } = props;
  const display = displayField === undefined ? "name" : displayField;
  const id = idField === undefined ? "id" : idField;
  return (
    <div>
      <Select
        showSearch
        disabled={list == undefined || list.length == 0 || disabled == true}
        style={{
          width: "100%",
          border: errorField !== undefined && errorField.includes(field) ? "1px solid red" : "1px solid #d9d9d9",
        }}
        placeholder={placeholder}
        optionFilterProp="children"
        value={
          productInfo === undefined || productInfo[field] == undefined ||
          list.filter((x) => x[id] == productInfo[field]).length == 0
            ? null
            : productInfo[field]
        }
        onChange={(value) => {
          var info = productInfo;
          if (info !== undefined) {
            info[field] = value;
            if (errorField !== undefined && errorField.includes(field)) {
              var errors = errorField.filter((x) => x != field);
              setErrorField([...errors]);
            }
            if (setProductInfo !== undefined) {
              setProductInfo({ ...info });
            }
          }

          if(onChangeHandle !== undefined){
            onChangeHandle(value)
          }
        }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        
        {list !== undefined &&
          list.map((info) => {
            return (
              <Option
                key={`${field}_key${info.id}`}
                value={info.id}
                style={{
                  fontWeight:
                    isCategory == true && info.parent == null
                      ? "bold"
                      : "normal",
                  color: info.active == false ? "var(--greyColor)" : "var(--blackColor)",
                }}
              >
                {(isCategory == true && info.parent != null)
                  ? "---" + info[display]
                  : info[display]}
              </Option>
            );
          })}
      </Select>
    </div>
  );
}

export default ProductTypeSelector;
