
import { Modal, Spin, Row, Col, Radio, message } from "antd";
import ProductDescriptionInput from "components/ProductPage/ProductDescriptionInput/ProductDescriptionInput";
import { useState } from "react";
import { confirmable } from "react-confirm";
import { isEmpty } from "utils/GlobalFunctions";
import ClientCaptcha from "react-client-captcha"
import "react-client-captcha/dist/index.css"

import "./PaymentDialog.css"

function PaymentDialog(props) {
  const { show, proceed, totalBudget } = props;
  const [cartItemInfo, setCartItemInfo] = useState({ customer_name: '', payment_method: 1, current_code: '' })
  const [captchaCode, setCaptchaCode] = useState("")

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(cartItemInfo, "customer_name", errorList);
    return errorList.length == 0;
  };
  const onOkAction = async () => {
    if ((await validateFields()) === false) {
      message.error("Please Enter Your Name")
      return
    }
    // if (captchaCode !== cartItemInfo["current_code"] && cartItemInfo["payment_method"] == 1) {
    //   message.error("Please Enter correct captcha code")
    //   return
    // }
    proceed({
      cartItemInfo: cartItemInfo,
    });
  }
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="payment-confirm-dlg"
      closable={true}
      maskClosable={false}
      title={`Payment Confirm : $` + totalBudget}
      onOk={onOkAction}
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ paddingBottom: 5 }}>&nbsp;Your Name*</div>
          <ProductDescriptionInput
            placeholder="Enter your name"
            field={"customer_name"}
            productInfo={cartItemInfo}
            setProductInfo={setCartItemInfo}
          />
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ padding : 3}}>
            <Radio.Group
              style={{ textAlign: "left", color: "var(--blackColor)" }}
              onChange={(e) =>
                setCartItemInfo({ ...cartItemInfo, payment_method: e.target.value })
              }
              value={cartItemInfo.payment_method}
              defaultValue={0}
            >
              <Radio style={{ color: 'var(--whiteColor)' }} value={0}>PayPal</Radio>
              <Radio style={{ color: 'var(--whiteColor)' }} value={1}>Cash</Radio>
            </Radio.Group>
          </div>

        </Col> */}
        {/* {cartItemInfo.payment_method == 1 && <>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}
            style={{ marginTop: 10 }}
          >
            <div>Captcha Code</div>
            <ProductDescriptionInput
              placeholder="Enter Captcha Code"
              field={"current_code"}
              productInfo={cartItemInfo}
              setProductInfo={setCartItemInfo}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <ClientCaptcha captchaCode={(code) => {
              console.log(code)
              setCaptchaCode(code)
            }} />
          </Col>

        </>} */}
      </Row>

    </Modal>
  );
}

export default confirmable(PaymentDialog);
