import { googleKey } from "config/CONSTANTS";

export const BASE_URL = "https://fnb-max.com/backend/admin";
export const SOCKET_URL = "https://www.shoproasters.com:5000";

// export const BASE_URL = "http://127.0.0.1/cafe/admin";
// export const SOCKET_URL = "http://127.0.0.1:5000";

//User backend base url

export const urlMainCategoryList = BASE_URL + "/Sales/getMainCategoryList";
export const urlSubCategoryList = BASE_URL + "/Sales/getSubCategoryList";
export const urlSalesItemList = BASE_URL + "/Sales/getSalesItemList";

export const urlMakePayWithPaypal = BASE_URL + "/Sales/makePayWithPaypal";
export const urlMakePayWithCash = BASE_URL + "/Sales/makePayWithCash";

export const urlContactUs = BASE_URL + "/contact-us";
export const urlUserLogin = BASE_URL + "/login";

export const SOCKET_EVENT_NAME = "socket_event_name";
export const SOCKET_EVENT_DATA = "socket_event_data";

export const SOCKET_EVENT_S_NEW_ORDER = "socket_event_s_new_order";

