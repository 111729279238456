import React, { useState } from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import "./SaleItemWidget.css"
import { useEffect } from 'react';
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import { Input, Radio, Space, Row, Col, Checkbox } from 'antd';
const { TextArea } = Input;


function SaleItemWidget(props) {
  const { info, onClickEdit, onClickDelete } = props
  const { dataList, setDataList, AddCartAction, RemoveCartAction, handleChangeInfoforEachItem, type } = props;

  const [detailShown, setDetailShown] = useState(false);
  const [qty, setQty] = useState(1);
  const [radioOption, setRadioOption] = useState(0);
  const [note, setNote] = useState("");
  const [dataExtraList, setDataExtraList] = useState([]);
  const [recipeExtraList, setRecipeExtraList] = useState([]);

  useEffect(() => {
    if (type === "2") {
      setQty(info.enter_qty)
      setRadioOption(info.radioOption)
      setNote(info.note)
    }
    if (info.extraOptionList !== null && info.extraOptionList !== undefined) {
      setDataExtraList([...info.extraOptionList])
    }
    if (info.recipe_extra !== null && info.recipe_extra !== undefined) {
      setRecipeExtraList([...info.recipe_extra])
    }
  }, [type]);
  const getItemPrice = () => {
    var result = 0
    if (type === "0") {
      result = 100 * info.recipeList[radioOption].average_price_per_unit / info.recipeList[radioOption].sell_price_percentage
    }
    else if (type === "1") {
      result = info.recipeList[radioOption].total_price
    }
    else if (type === "2") {
      result = info.recipeList[radioOption].total_price
    }
    return Math.round(result)
  }

  const getItemDescription = () => {
    let desc = info.recipeList[radioOption].description
    if (desc !== undefined && desc !== "" && desc !== null) {
      return <div dangerouslySetInnerHTML={{ __html:  desc}} />
    }
    return ""
  }

  const getBackgroundImage = () => {
    let style = "linear-gradient(to bottom, rgba(0, 0, 0, 0) 60px, rgba(0, 0, 0, 0.7) 100%), url(" + encodeURI(info.thumbnail_image) + ")"
    return style
  }

  if (type === undefined)
    return (<div></div>)

  const onRadioChange = e => {
    info["radioOption"] = e.target.value
    handleChangeInfoforEachItem(info)
    setRadioOption(e.target.value);
  };
  const radioUI = info.recipeList == undefined || info.recipeList.length == 0 ? <div>You don't have any Item list</div> : info.recipeList.map((info, index) => {
    return (
      <Radio value={index} key={index}>{info.name}</Radio>
    );
  });
  const onChange = (group, item, checked) => {
    if (dataExtraList == null || dataExtraList.length === 0) {
      return
    }
    let tmp_record = { ...group }
    let tmp_optionList = [...tmp_record["optionList"]]
    var storeIndex = tmp_optionList.findIndex(x => x.id == item.id)
    var tmpData = tmp_optionList[storeIndex]
    tmpData["value"] = checked
    tmp_optionList.splice(storeIndex, 1, { ...tmpData })

    let multi_checked_list = tmp_optionList.filter(x => x.value === true)
    if (multi_checked_list !== undefined && multi_checked_list.length > 1) {
      for (let i = 0; i < tmp_optionList.length; i++) {
        if (i !== storeIndex) {
          tmp_optionList[i]["value"] = false
        }
      }
    }

    tmp_record["optionList"] = [...tmp_optionList]
    let updateData = [...dataExtraList]
    var storeIndex = updateData.findIndex(x => x.id == tmp_record.id);
    updateData.splice(storeIndex, 1, tmp_record);
    var tmpData = [...updateData];
    setDataExtraList(tmpData);

    let tmp_extraOptionList = []
    let tmp_dataExtraList = [...dataExtraList]
    for (let i = 0; i < tmp_dataExtraList.length; i++) {
      let tmp_optionList = [...(tmp_dataExtraList[i].optionList)]
      let tmp = []
      for (let j = 0; j < tmp_optionList.length; j++) {
        let tmp_obj = {
          name: tmp_optionList[j].name,
          value: tmp_optionList[j].value,
          id: tmp_optionList[j].id,
        }
        tmp.push({ ...tmp_obj })
      }
      tmp_extraOptionList.push({
        optionList: [...tmp],
        name: tmp_dataExtraList[i].name,
        id: tmp_dataExtraList[i].id,
      })
    }
    info["extraOptionList"] = [...tmp_extraOptionList]
    handleChangeInfoforEachItem(info)

  };

  const optionListUI = (group) => {
    const list = group.optionList
    return list == undefined || list.length == 0 ? <div></div> : list.map((info, index) => {
      return (
        <Col xs={24} sm={24} md={24} lg={24} xl={24} key={index}>
          <Checkbox
            checked={info.value}
            onChange={(e) => {
              onChange(group, info, e.target.checked)
            }}
          >{info.name}</Checkbox>
        </Col>
      );
    });
  }

  const extraOptionListUI = dataExtraList == undefined || dataExtraList.length == 0 ? <div></div> : dataExtraList.map((info, index) => {
    return (
      <Col xs={24} sm={24} md={24} lg={24} xl={24} key={index}>
        <div>
          <div style={{ fontSize: 16, color: "var(--blueColor)", paddingTop: 4 }}>{info.name}</div>
          <div>
            {optionListUI(info)}
          </div>
        </div>
      </Col>
    );
  });

  const onSelectRecipeChange = (item, checked) => {
    let updateData = [...recipeExtraList];
    var storeIndex = updateData.findIndex(x => x.id == item.id);
    let tmpData = updateData[storeIndex]
    tmpData["value"] = checked
    updateData.splice(storeIndex, 1, tmpData);
    setRecipeExtraList(updateData)

    let new_recipeExtraList = []
    let tmp_recipeExtraList = [...recipeExtraList]
    for (let i = 0; i < tmp_recipeExtraList.length; i++) {
      let tmp = tmp_recipeExtraList[i]
      new_recipeExtraList.push({ ...tmp })
    }
    info["recipe_extra"] = [...new_recipeExtraList]
    handleChangeInfoforEachItem(info)
  }

  const extraRecipeListUI = recipeExtraList == undefined || recipeExtraList.length == 0 ? <div></div> :
    <div>
      <div style={{ marginTop: 10 }}>Extra Order</div>
      {recipeExtraList.map((info_extra, index) => {
        return (
          <Col xs={24} sm={24} md={24} lg={24} xl={24} key={index}>
            <Checkbox
              checked={info_extra.value}
              onChange={(e) => {
                onSelectRecipeChange(info_extra, e.target.checked)
              }}
            >{info_extra.name} - {info.payment_currency}&nbsp;{Math.round(info_extra.total_price)} </Checkbox>
          </Col>
        );
      })}
    </div>


  return (
    <div className={"one-product-item-container"}
      style={{ position: "relative" }}
      onMouseEnter={() => setDetailShown(true)}
      onMouseLeave={() => setDetailShown(false)}>
      <div className='one-product-item-rect'>
        <img src={encodeURI(info.thumbnail_image)} className='one-product-item-image' />
      </div>
      <div className="one-product-child">
        <Row justify="space-between" align="stretch ">
          <Col xs={20}><div className="one-product-info-title">{info.name}</div></Col>
          <Col xs={4}><p>{info.payment_currency}&nbsp;{getItemPrice()}</p></Col>
        </Row>
        <Row justify="space-between" align="stretch ">
          <Col xs={24}>
            <div className="one-product-info-description">{getItemDescription()}</div>
          </Col>
        </Row>
        <div className='one-product-option-box'>
          <div>
            <Row>
              <Radio.Group onChange={onRadioChange} value={radioOption} size="large">
                <Space direction="vertical" align="start">
                  {radioUI}
                </Space>
              </Radio.Group>
            </Row>
            <Row>
              <Space direction="vertical" align="start">
                {extraRecipeListUI}
              </Space>
            </Row>
            <Row>
              <Space direction="vertical" align="start">
                {extraOptionListUI}
              </Space>
            </Row>
          </div>

          {info.show_note === "1" &&
            <Row style={{ paddingTop: 8 }}>
              <div>Special Instruction (optional)</div>
              <TextArea rows={1}
                value={
                  note
                }
                onChange={(e) => {
                  setNote(e.target.value)
                  info["note"] = e.target.value
                  handleChangeInfoforEachItem(info)
                }}

              />
            </Row>
          }
        </div>

      </div>

      <div className="one-product-footer">
        <div className="kMLsts">
          <div className="ikblOk">
            <button disabled="" type="button" className="hBSnPp"
              onClick={() => {
                if (qty > 1) {
                  info["enter_qty"] = qty - 1
                  handleChangeInfoforEachItem(info)
                  setQty(qty - 1)
                }
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 240 240">
                <g>
                  <path fill="currentColor" d="M15,127c-2.75,0-5-2.25-5-5v-4c0-2.75,2.25-5,5-5h210c2.75,0,5,2.25,5,5v4c0,2.75-2.25,5-5,5H15z"></path>
                </g>
              </svg>
            </button>
            <div className="iVsyrq">{qty}</div>
            <button type="button" className="tdWfM"
              onClick={() => {
                info["enter_qty"] = qty + 1
                handleChangeInfoforEachItem(info)
                setQty(qty + 1)
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 240 240">
                <g>
                  <path fill="currentColor" d="M127,225c0,2.75-2.25,5-5,5h-4c-2.75,0-5-2.25-5-5V15c0-2.75,2.25-5,5-5h4c2.75,0,5,2.25,5,5V225z"></path>
                </g>
                <g>
                  <path fill="currentColor" d="M15,127c-2.75,0-5-2.25-5-5v-4c0-2.75,2.25-5,5-5h210c2.75,0,5,2.25,5,5v4c0,2.75-2.25,5-5,5H15z"></path>
                </g>
              </svg>
            </button>
          </div>
        </div>

        {
          type !== "2" ?
            <button className="kXXIGE"
              onClick={async () => {
                var res = await showConfirmDlgWidget({ title: info.name, content: "Your order has been added to your checkout you find in the upper right corner.", okText: "OK" });
                if (res == false) return;
                info["enter_qty"] = qty
                info["radioOption"] = radioOption
                info["note"] = note

                let tmp_extraOptionList = []
                let tmp_dataExtraList = [...dataExtraList]
                for (let i = 0; i < tmp_dataExtraList.length; i++) {
                  let tmp_optionList = [...(tmp_dataExtraList[i].optionList)]
                  let tmp = []
                  for (let j = 0; j < tmp_optionList.length; j++) {
                    let tmp_obj = {
                      name: tmp_optionList[j].name,
                      value: tmp_optionList[j].value,
                      id: tmp_optionList[j].id,
                    }
                    tmp.push({ ...tmp_obj })
                  }
                  tmp_extraOptionList.push({
                    optionList: [...tmp],
                    name: tmp_dataExtraList[i].name,
                    id: tmp_dataExtraList[i].id,
                  })
                }
                info["extraOptionList"] = [...tmp_extraOptionList]

                let new_recipeExtraList = []
                let tmp_recipeExtraList = [...recipeExtraList]
                for (let i = 0; i < tmp_recipeExtraList.length; i++) {
                  let tmp = tmp_recipeExtraList[i]
                  new_recipeExtraList.push({ ...tmp })
                }
                info["recipe_extra"] = [...new_recipeExtraList]
                AddCartAction(info)
              }}

            >
              <span className="ibdYrf">Add to Order</span>
            </button>
            :
            <button className="kXXIGE"
              style={{ backgroundColor: "rgb(236 79 55)" }}
              onClick={async () => {
                var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
                if (res == false) return;
                RemoveCartAction(info)
              }}
            >
              <span className="ibdYrf">Remove</span>
            </button>
        }

      </div>
    </div>
  )
}

export default SaleItemWidget
