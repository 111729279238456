import "./ButtonWithIcon.css";
export const ButtonWithIcon = (props) => {
  const { icon, text, onClick } = props;
  return (
    <div className="button-with-icon custom-button" onClick={onClick}>
      <div>{icon}</div>
      <div>{text}</div>
    </div>
  );
};

export default ButtonWithIcon;
