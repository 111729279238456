import * as React from "react";

import { Input, Typography } from "antd";

const { Text } = Typography;
function ProductDescriptionInput(props) {
  const { productInfo, setProductInfo } = props;
  const { placeholder, field } = props;
  const { errorField, setErrorField } = props;
  const { disabled, type, is_password } = props;
  return (
    <div style={{ textAlign: "left", marginBottom: 10 }}>
      <Input
        style={{
          border: errorField !== undefined && errorField.includes(field)
            ? "1px solid red"
            : "1px solid #d9d9d9",
          borderRadius: "8px",
          padding: "8px 12px"
        }}
        placeholder={placeholder}
        value={ productInfo === undefined || productInfo[field] == undefined ? "" : productInfo[field]}
        disabled={disabled === undefined ? false : disabled}
        type={type === undefined ? "text" : type}
        onChange={(e) => {
          var info = productInfo;
          info[field] = e.target.value;
          if (errorField !== undefined && errorField.includes(field)) {
            var errors = errorField.filter((x) => x != field);
            // console.log(errors);
            setErrorField([...errors]);
          }
          setProductInfo({ ...info });
        }}
      />
    </div>
  );
}

export default ProductDescriptionInput;
